import React from "react"

const Invitation = () => (
  <div>
    <h2>You have been invited</h2>

    <p>You have been invited to create a user account for Henry Gatewood Construction. Follow this link to accept the invite:</p>
    <p><a href="{{ .SiteURL }}/admin/#invite_token={{ .Token }}">Accept the invite</a></p>
  </div>
)

export default Invitation
